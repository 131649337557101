import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";

import {
  GetPaymentMethodsResponse,
  OwnershipType,
  PaymentMethod,
  PaymentMethodType,
  PaymentTransaction,
  ProcessingStatusType,
  ValidationErrors,
} from "../../lib/api/models";
import { useGetBankNames } from "../../lib/api/queries";
import { reportError } from "../../lib/bugReporting";
import { INPUT_MASKS, maskInput } from "../../lib/formatting";
import {
  useIsAgentRoleACHPaper,
  useIsAgentRoleViewPCIData,
} from "../../lib/roleContext";
import {
  OWNERSHIP_OPTIONS,
  PAYMENT_METHOD_TYPE_OPTIONS,
  getStylizedPaymentMethodName,
  isCardMemberAccount,
} from "../../lib/sourceAccounts";
import SourceAccountSelect from "../MakePayment/SourceAccountSelect";
import { NEW_SOURCE_ACCOUNT } from "../MakePayment/contants";
import StyledError from "../StyledError";
import StyledPasswordInput from "../StyledPasswordInput";
import StyledRadioButton from "../StyledRadioButton";
import ExpirationDateInput from "../common/ExpirationDateInput";
import Select from "../common/Select";
import StyledInput from "../common/inputs/StyledInput";
import styles from "./PaymentInfo.module.css";

const strings = {
  accountNumber: "Account Number",
  accountNumberRequired: "Account Number*",
  ach: "ACH",
  addNewSourceAccount: "Add New Source Account",
  cardNumber: "Card Number",
  cardNumberRequired: "Card Number*",
  checkingAccount: "Checking Account",
  confirmAccountNumber: "Confirm Account Number*",
  confirmCardNumber: "Confirm Card Number*",
  expirationDate: "Expiration Date*",
  ownership: "Ownership*",
  paper: "Paper",
  paymentMethodType: "Method Type",
  paymentMethodTypeRequired: "Method Type*",
  abaNumber: "ABA Number",
  abaNumberRequired: "ABA Number*",
  select: "Select",
  accountNumbersDontMatch: "Account Numbers don't match",
  cardNumbersDontMatch: "Card Numbers don't match",
  bankNameRequired: "Bank Name*",
  somethingWentWrong: "Something went wrong loading bank names",
};

const ABA_NUMBER_MAX_LENGTH = 9;

export type PaymentOption =
  | "amount_due"
  | "amount_delq"
  | "amount_total"
  | "last_statement_balance"
  | "current_balance"
  | "custom";

type PaymentInfoFormInput = {
  ownerType?: OwnershipType;
  paymentMethodId: string;
  currentPaymentMethod?: PaymentMethod;
  removedPaymentMethod?: PaymentMethod;
  paymentMethodType?: PaymentMethodType;
};

type CheckingsAccountFormInput = {
  abaNumber: string;
  bankName: string;
  accountNumber: string;
  confirmAccountNumber: string;
  processingStatusType: ProcessingStatusType;
};

type CreditOrDebitFormInput = {
  cardNumber: string;
  confirmCardNumber: string;
  expirationDate: string;
};

export type PaymentInfoFormState = PaymentInfoFormInput &
  CheckingsAccountFormInput &
  CreditOrDebitFormInput & {
    paymentMethodName: string;
  };

type Props = {
  rowClassName?: string;
  getPaymentMethodsResponse: GetPaymentMethodsResponse;
  formState: PaymentInfoFormState;
  generalDispatch: (payload: Partial<PaymentInfoFormState>) => void;
  isLoading?: boolean;
  updateNameOnPaymentMethodChange?: (
    firstName: string,
    lastName: string,
    firstName2?: string | null,
    lastName2?: string | null,
  ) => void;
  updateAddressOnPaymentMethodChange?: (
    addressLine1: string,
    city: string,
    stateCode: string,
    zipcode: string,
    phoneNumber?: string | null,
    addressLine2?: string | null,
  ) => void;
  networkValidationMessages?: ValidationErrors;
  clearNetworkValidationMessage: (...keys: string[]) => void;
  scheduledPaymentTransaction?: PaymentTransaction;
};

export default function PaymentInfo({
  rowClassName,
  getPaymentMethodsResponse,
  formState,
  generalDispatch,
  isLoading,
  updateNameOnPaymentMethodChange,
  updateAddressOnPaymentMethodChange,
  networkValidationMessages,
  clearNetworkValidationMessage,
  scheduledPaymentTransaction,
}: Props) {
  const {
    ownerType,
    paymentMethodId,
    paymentMethodType,
    abaNumber,
    bankName,
    accountNumber,
    confirmAccountNumber,
    processingStatusType,
    cardNumber,
    confirmCardNumber,
    expirationDate,
    currentPaymentMethod,
    removedPaymentMethod,
  } = formState;

  const { paymentMethodOptions } = useMemo(() => {
    let currentPM: PaymentMethod[] =
      getPaymentMethodsResponse.thirdPartyAccounts;
    switch (ownerType) {
      case "ACCOUNT_OWNER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "ACCOUNT_OWNER",
        );
        break;
      case "AUTHORIZED_USER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "AUTHORIZED_USER",
        );
    }

    if (removedPaymentMethod && removedPaymentMethod.ownerType === ownerType) {
      currentPM.push(removedPaymentMethod);
    }

    return { paymentMethodOptions: currentPM };
  }, [
    getPaymentMethodsResponse.cardMemberAccounts,
    getPaymentMethodsResponse.thirdPartyAccounts,
    removedPaymentMethod,
    ownerType,
  ]);

  // Check if source account associated with the payment to be edited is removed/hidden
  useEffect(() => {
    const scheduledPaymentMethod = scheduledPaymentTransaction?.paymentMethod;
    if (!scheduledPaymentMethod || removedPaymentMethod) {
      return;
    }

    let isPaymentMethodPresent = false;
    if (isCardMemberAccount(scheduledPaymentMethod)) {
      isPaymentMethodPresent =
        getPaymentMethodsResponse.cardMemberAccounts.some(
          (account) => account.id === scheduledPaymentMethod.id,
        );
    } else {
      isPaymentMethodPresent =
        getPaymentMethodsResponse.thirdPartyAccounts.some(
          (account) => account.id === scheduledPaymentMethod.id,
        );
    }
    if (!isPaymentMethodPresent) {
      generalDispatch({ removedPaymentMethod: scheduledPaymentMethod });
    }
  }, [
    generalDispatch,
    getPaymentMethodsResponse.cardMemberAccounts,
    getPaymentMethodsResponse.thirdPartyAccounts,
    removedPaymentMethod,
    scheduledPaymentTransaction,
  ]);

  const clearPaymentInfo = () => {
    generalDispatch({
      abaNumber: "",
      accountNumber: "",
      confirmAccountNumber: "",
      processingStatusType: "ACH",
      cardNumber: "",
      confirmCardNumber: "",
      expirationDate: "",
      bankName: "",
    });
    if (updateNameOnPaymentMethodChange) {
      updateNameOnPaymentMethodChange("", "", undefined, undefined);
    }
    if (updateAddressOnPaymentMethodChange) {
      updateAddressOnPaymentMethodChange("", "", "", "", "", undefined);
    }
    clearNetworkValidationMessage(
      "abaNumber",
      "accountNumber",
      "cardNumber",
      "expirationDate",
      "paymentMethodId",
      "sourceAccountId",
    );
  };

  const isOwner = isCardMemberAccount({ ownerType: ownerType ?? "UNKNOWN" });
  const updatePaymentMethod = (id: string) => {
    if (id === NEW_SOURCE_ACCOUNT) {
      generalDispatch({
        paymentMethodId: id,
        currentPaymentMethod: undefined,
        paymentMethodType: "checking_account",
      });
      clearPaymentInfo();
      return;
    }

    const paymentMethod = paymentMethodOptions.find((pm) => pm.id === id);
    if (!paymentMethod) {
      reportError(
        new Error("Programmer Error: Unable to find payment method with id", {
          cause: id,
        }),
      );
      return;
    }

    if (updateNameOnPaymentMethodChange) {
      /* Only existing third party accounts populate second instance of first and last name */
      if (!isOwner && paymentMethod.type === "checking_account") {
        updateNameOnPaymentMethodChange(
          paymentMethod.cardHolderFirstName,
          paymentMethod.cardHolderLastName,
          paymentMethod.cardHolderFirstName2,
          paymentMethod.cardHolderLastName2,
        );
      } else {
        updateNameOnPaymentMethodChange(
          paymentMethod.cardHolderFirstName,
          paymentMethod.cardHolderLastName,
          undefined,
          undefined,
        );
      }
    }
    if (updateAddressOnPaymentMethodChange) {
      updateAddressOnPaymentMethodChange(
        paymentMethod.addressLine1,
        paymentMethod.city,
        paymentMethod.stateCode,
        paymentMethod.zipcode,
        paymentMethod.phoneNumber,
        paymentMethod.addressLine2,
      );
    }
    clearNetworkValidationMessage(
      "abaNumber",
      "accountNumber",
      "cardNumber",
      "expirationDate",
    );

    const paymentMethodName = getStylizedPaymentMethodName(paymentMethod);

    if (paymentMethod.type === "checking_account") {
      generalDispatch({
        paymentMethodId: id,
        currentPaymentMethod: paymentMethod,
        paymentMethodType: paymentMethod.type,
        abaNumber: paymentMethod.abaNumber,
        accountNumber: paymentMethod.accountNumber,
        bankName: "",
        processingStatusType: "ACH",
        paymentMethodName,
        cardNumber: "",
        expirationDate: "",
      });
    } else if (
      paymentMethod.type === "credit_card" ||
      paymentMethod.type === "debit_card"
    ) {
      generalDispatch({
        paymentMethodId: id,
        currentPaymentMethod: paymentMethod,
        paymentMethodType: paymentMethod.type,
        cardNumber: paymentMethod.cardNumber,
        expirationDate: paymentMethod.expirationDate,
        paymentMethodName,
        abaNumber: "",
        accountNumber: "",
        bankName: "",
        processingStatusType: undefined,
      });
    }
  };

  const onUpdateOwnership = (v: OwnershipType) => {
    generalDispatch({
      ownerType: v,
      paymentMethodId: "",
      currentPaymentMethod: undefined,
    });
    clearPaymentInfo();
  };

  const disableEditingSourceAccountIfRemoved =
    currentPaymentMethod &&
    removedPaymentMethod &&
    currentPaymentMethod.id === removedPaymentMethod.id;
  const accountInfoInputsDisabled =
    paymentMethodId !== NEW_SOURCE_ACCOUNT ||
    isLoading ||
    disableEditingSourceAccountIfRemoved;

  const canSetProcessingType = useIsAgentRoleACHPaper();
  const canViewPCIData = useIsAgentRoleViewPCIData();

  const [localValidationMessages, setLocalValidationMessages] = useState<
    ValidationErrors | undefined
  >(undefined);

  const accountNumberInputsOnFocus = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: [],
      };
    });
  };

  const accountNumberInputsOnBlur = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber:
          !!confirmAccountNumber && accountNumber !== confirmAccountNumber
            ? [strings.accountNumbersDontMatch]
            : [],
      };
    });
  };

  const cardNumberInputsOnFocus = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmCardNumber: [],
      };
    });
  };

  const cardNumberInputsOnBlur = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmCardNumber:
          !!confirmCardNumber && cardNumber !== confirmCardNumber
            ? [strings.cardNumbersDontMatch]
            : [],
      };
    });
  };

  const {
    data: bankNamesResponse,
    isLoading: isLoadingBankNames,
    isError: isBankNamesError,
    error: bankNamesError,
    refetch: refetchBankNames,
  } = useGetBankNames(
    paymentMethodType === "checking_account" &&
      abaNumber.length === ABA_NUMBER_MAX_LENGTH
      ? abaNumber
      : "",
  );

  return (
    <>
      <Row className={rowClassName}>
        <Col xs={2}>
          <Select
            className={styles.alignSelect}
            contentClassName={styles.selectContentPadding}
            selectedLabelClassName={styles.selectContentLabel}
            selectedValue={ownerType}
            options={OWNERSHIP_OPTIONS}
            onChange={(v: OwnershipType) => {
              onUpdateOwnership(v);
              clearNetworkValidationMessage("ownerType", "authorizerType");
            }}
            label={strings.ownership}
            placeholder={strings.select}
            disabled={isLoading}
            validationMessages={([] as string[])
              .concat(networkValidationMessages?.ownerType ?? [])
              .concat(networkValidationMessages?.authorizerType ?? [])}
          />
        </Col>
        <Col xs={2}>
          <SourceAccountSelect
            className={styles.sourceAccountSelect}
            selectedValue={paymentMethodId}
            accounts={paymentMethodOptions}
            onChange={(v) => {
              updatePaymentMethod(v);
              clearNetworkValidationMessage(
                "paymentMethodId",
                "sourceAccountId",
              );
            }}
            onAddNew={(v) => updatePaymentMethod(v)}
            disabled={isLoading || !ownerType}
            validationMessages={([] as string[])
              .concat(networkValidationMessages?.paymentMethodId ?? [])
              .concat(networkValidationMessages?.sourceAccountId ?? [])}
          />
        </Col>
        <Col xs={2}>
          <Select
            className={styles.alignSelect}
            contentClassName={styles.selectContentPadding}
            selectedLabelClassName={styles.selectContentLabel}
            selectedValue={paymentMethodType}
            options={PAYMENT_METHOD_TYPE_OPTIONS}
            onChange={(v: PaymentMethodType) =>
              generalDispatch({ paymentMethodType: v })
            }
            label={
              accountInfoInputsDisabled
                ? strings.paymentMethodType
                : strings.paymentMethodTypeRequired
            }
            placeholder={strings.select}
            disabled={accountInfoInputsDisabled}
          />
        </Col>
      </Row>
      {paymentMethodType === "checking_account" ? (
        <Row className={rowClassName}>
          <Col xs={2}>
            <div className={styles.paymentMethodTypeInputContainer}>
              <StyledInput
                className={styles.abaNumber}
                label={
                  accountInfoInputsDisabled
                    ? strings.abaNumber
                    : strings.abaNumberRequired
                }
                value={abaNumber}
                onChange={(e) => {
                  const newValue = maskInput(
                    INPUT_MASKS.abaNumber,
                    e.currentTarget.value,
                  );
                  generalDispatch({
                    abaNumber: newValue,
                  });
                  if (bankName && newValue.length !== ABA_NUMBER_MAX_LENGTH) {
                    generalDispatch({ bankName: "" });
                  }
                  clearNetworkValidationMessage("abaNumber");
                }}
                disabled={accountInfoInputsDisabled}
                maxLength={ABA_NUMBER_MAX_LENGTH}
                validationMessages={
                  networkValidationMessages
                    ? networkValidationMessages["abaNumber"]
                    : undefined
                }
              />
            </div>
          </Col>
          <Col xs={2}>
            <Select
              className={styles.alignSelect}
              contentClassName={styles.selectContentPadding}
              selectedLabelClassName={styles.selectContentLabel}
              selectedValue={bankName}
              options={
                bankNamesResponse?.bankNames.map((n) => ({
                  label: n,
                  value: n,
                })) ?? []
              }
              onChange={(v: string) => generalDispatch({ bankName: v })}
              label={strings.bankNameRequired}
              placeholder={strings.select}
              disabled={isLoadingBankNames || !bankNamesResponse}
            />
          </Col>
          <Col xs={2}>
            <StyledPasswordInput
              className={styles.accountNumber}
              label={
                accountInfoInputsDisabled
                  ? strings.accountNumber
                  : strings.accountNumberRequired
              }
              value={accountNumber}
              onChange={(e) => {
                generalDispatch({
                  accountNumber: maskInput(
                    INPUT_MASKS.checkingAccountNumber,
                    e.currentTarget.value,
                  ),
                });
                clearNetworkValidationMessage("accountNumber");
              }}
              disabled={accountInfoInputsDisabled}
              validationMessages={
                networkValidationMessages
                  ? networkValidationMessages["accountNumber"]
                  : undefined
              }
              maxLength={20}
              minLength={4}
              alwaysHideInput={!canViewPCIData}
              onFocus={accountNumberInputsOnFocus}
              onBlur={accountNumberInputsOnBlur}
            />
          </Col>
          <Col xs={3}>
            <div className={styles.paymentMethodTypeInputContainer}>
              {paymentMethodId === NEW_SOURCE_ACCOUNT ? (
                <StyledPasswordInput
                  className={styles.confirmAccountNumber}
                  label={strings.confirmAccountNumber}
                  value={confirmAccountNumber}
                  onChange={(e) =>
                    generalDispatch({
                      confirmAccountNumber: maskInput(
                        INPUT_MASKS.checkingAccountNumber,
                        e.currentTarget.value,
                      ),
                    })
                  }
                  disabled={isLoading}
                  maxLength={20}
                  minLength={4}
                  onFocus={accountNumberInputsOnFocus}
                  onBlur={accountNumberInputsOnBlur}
                  validationMessages={
                    localValidationMessages?.confirmAccountNumber
                  }
                  alwaysHideInput={!canViewPCIData}
                />
              ) : null}
              {canSetProcessingType ? (
                <div className={styles.communicationsContainer}>
                  <StyledRadioButton
                    className={styles.communicationsRadioButton}
                    label={strings.ach}
                    checked={processingStatusType === "ACH"}
                    onChange={() =>
                      generalDispatch({ processingStatusType: "ACH" })
                    }
                    disabled={isLoading}
                  />
                  <StyledRadioButton
                    label={strings.paper}
                    checked={processingStatusType === "PAPER"}
                    onChange={() =>
                      generalDispatch({ processingStatusType: "PAPER" })
                    }
                    disabled={isLoading}
                  />
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : paymentMethodType === "credit_card" ||
        paymentMethodType === "debit_card" ? (
        <Row className={rowClassName}>
          <Col xs={2}>
            <div className={styles.paymentMethodTypeInputContainer}>
              <StyledPasswordInput
                className={styles.cardNumber}
                label={
                  accountInfoInputsDisabled
                    ? strings.cardNumber
                    : strings.cardNumberRequired
                }
                value={cardNumber}
                onChange={(e) => {
                  generalDispatch({
                    cardNumber: maskInput(
                      INPUT_MASKS.creditCard,
                      e.currentTarget.value,
                    ),
                  });
                  clearNetworkValidationMessage("cardNumber");
                }}
                alwaysHideInput={!canViewPCIData}
                disabled={accountInfoInputsDisabled}
                maxLength={16}
                onFocus={cardNumberInputsOnFocus}
                onBlur={cardNumberInputsOnBlur}
                validationMessages={
                  networkValidationMessages
                    ? networkValidationMessages["cardNumber"]
                    : undefined
                }
              />
            </div>
          </Col>
          {paymentMethodId === NEW_SOURCE_ACCOUNT ? (
            <Col xs={2}>
              <StyledPasswordInput
                className={styles.confirmCardNumber}
                label={strings.confirmCardNumber}
                value={confirmCardNumber}
                onChange={(e) =>
                  generalDispatch({
                    confirmCardNumber: maskInput(
                      INPUT_MASKS.creditCard,
                      e.currentTarget.value,
                    ),
                  })
                }
                disabled={isLoading}
                maxLength={16}
                onFocus={cardNumberInputsOnFocus}
                onBlur={cardNumberInputsOnBlur}
                validationMessages={localValidationMessages?.confirmCardNumber}
                alwaysHideInput={!canViewPCIData}
              />
            </Col>
          ) : null}
          <Col xs={1}>
            <ExpirationDateInput
              label={strings.expirationDate}
              value={expirationDate}
              onChange={(v) => {
                generalDispatch({
                  expirationDate: v,
                });
                clearNetworkValidationMessage("expirationDate");
              }}
              disabled={isLoading || disableEditingSourceAccountIfRemoved}
              validationMessages={
                networkValidationMessages
                  ? networkValidationMessages["expirationDate"]
                  : undefined
              }
            />
          </Col>
        </Row>
      ) : null}
      {isBankNamesError ? (
        <Row>
          <Col>
            <StyledError
              error={bankNamesError}
              errorTitle={strings.somethingWentWrong}
              refetch={refetchBankNames}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
}
