import Subtool from "../../../common/Subtool";
import StyledButton from "../../../common/buttons/StyledButton";
import styles from "./ActiveEnrollmentActions.module.css";

const strings = {
  manageActions: "Manage Actions",
  removeAutopay: "Remove Autopay Enrollment",
  skipAutopay: "Skip Autopay Payment",
  thisMonthSkipped: "This Month's Payment Skipped",
};

type Props = {
  onRemove: () => void;
  onSkip?: () => void;
};

export function ActiveEnrollmentActions({ onRemove, onSkip }: Props) {
  return (
    <Subtool className={styles.container}>
      <span className={styles.subheader}>{strings.manageActions}</span>
      <StyledButton
        className={styles.skipAutopayButton}
        variant="secondary"
        onClick={onSkip}
        disabled={!onSkip}
      >
        {onSkip ? strings.skipAutopay : strings.thisMonthSkipped}
      </StyledButton>
      <StyledButton
        className={styles.removeAutopayButton}
        variant="danger"
        onClick={onRemove}
      >
        {strings.removeAutopay}
      </StyledButton>
    </Subtool>
  );
}
