import { useState } from "react";
import { ZodError, z } from "zod";
import { PartyType, PaymentAmountOptions } from "../../../lib/api/models";
import { reportError } from "../../../lib/bugReporting";
import {
  centsToDollarString,
  dollarStringToCents,
} from "../../../lib/formatting";
import {
  accountNumberSchema,
  firstNameValidationSchema,
  lastNameValidationSchema,
  routingNumberSchema,
} from "../../../lib/validation/schemas";

const MAX_FIXED_PAYMENT_AMOUNT_CENTS = 999999;
const MIN_FIXED_PAYMENT_AMOUNT_CENTS = 2000;

const strings = {
  paymentCentsMax: `Please enter a value below ${centsToDollarString(
    MAX_FIXED_PAYMENT_AMOUNT_CENTS + 1,
  )}.`,
  paymentCentsMin: `Please enter a value of ${centsToDollarString(
    MIN_FIXED_PAYMENT_AMOUNT_CENTS,
  )} or above.`,

  invalid: "Invalid amount",
};

type FieldsToValidate =
  | "firstName"
  | "lastName"
  | "abaNumber"
  | "ddaNumber"
  | "amountCents";

type FormInput = {
  party?: PartyType;
  firstName: string;
  lastName: string;
  abaNumber: string;
  ddaNumber: string;
  selectedPaymentAmount?: PaymentAmountOptions;
  fixedAmountInput: string;
};

type FormState = FormInput & {
  isComplete: boolean;
  isPersonalInfoInvalid: boolean;
  isAccountInfoInvalid: boolean;
  isPaymentAmountInvalid: boolean;
  validationMessages: Record<FieldsToValidate, string[]>;
};

const getValidationMessagesFromSchema = (
  value: string,
  schema: z.ZodString,
): string[] => {
  try {
    schema.parse(value);
  } catch (err) {
    if (err instanceof ZodError && err.errors.length > 0) {
      return err.errors.map((vm) => vm.message);
    }
  }
  return [];
};

const isPersonalInfoInvalid = (formState: FormState) => {
  if (formState.party === "CM") {
    return false;
  }

  if (formState.party === undefined) {
    return true;
  }

  if (!formState.firstName.trim().length || !formState.lastName.trim().length) {
    return true;
  }

  return (
    getValidationMessagesFromSchema(
      formState.firstName,
      firstNameValidationSchema,
    ).length > 0 ||
    getValidationMessagesFromSchema(
      formState.lastName,
      lastNameValidationSchema,
    ).length > 0
  );
};

const isAccountInfoInvalid = (formState: FormState) => {
  return (
    getValidationMessagesFromSchema(formState.abaNumber, routingNumberSchema)
      .length > 0 ||
    getValidationMessagesFromSchema(formState.ddaNumber, accountNumberSchema)
      .length > 0
  );
};

const isPaymentAmountInvalid = (formState: FormState) => {
  if (!formState.selectedPaymentAmount) {
    return true;
  }

  if (formState.selectedPaymentAmount === "FIXED") {
    return (
      !formState.fixedAmountInput ||
      dollarStringToCents(formState.fixedAmountInput) >
        MAX_FIXED_PAYMENT_AMOUNT_CENTS ||
      dollarStringToCents(formState.fixedAmountInput) <
        MIN_FIXED_PAYMENT_AMOUNT_CENTS
    );
  }

  return false;
};

const getValidationMessagesForAmountCents = (formState: FormState) => {
  if (
    !formState.selectedPaymentAmount ||
    formState.selectedPaymentAmount !== "FIXED" ||
    !formState.fixedAmountInput
  ) {
    return [];
  }

  const amountCents = dollarStringToCents(formState.fixedAmountInput);
  if (amountCents > MAX_FIXED_PAYMENT_AMOUNT_CENTS) {
    return [strings.paymentCentsMax];
  } else if (amountCents < MIN_FIXED_PAYMENT_AMOUNT_CENTS) {
    return [strings.paymentCentsMin];
  }
  return [];
};

const isFormComplete = (formState: FormState) => {
  const personalInfoInvalid = isPersonalInfoInvalid(formState);
  const accountInfoInvalid = isAccountInfoInvalid(formState);
  const paymentAmountInvalid = isPaymentAmountInvalid(formState);

  return (
    !!formState.party &&
    !personalInfoInvalid &&
    !accountInfoInvalid &&
    !paymentAmountInvalid
  );
};

export const useAutopayEnrollmentForm = (): [
  FormState,
  {
    generalDispatch: (payload: Partial<FormInput>) => void;
    getValidationMessages: (validationKey: FieldsToValidate) => void;
    clearValidationMessages: (validationKey: FieldsToValidate) => void;
  },
] => {
  const [formState, formDispatch] = useState<FormState>({
    party: undefined,
    firstName: "",
    lastName: "",
    abaNumber: "",
    ddaNumber: "",
    selectedPaymentAmount: undefined,
    fixedAmountInput: "",
    isComplete: false,
    validationMessages: {
      firstName: [],
      lastName: [],
      abaNumber: [],
      ddaNumber: [],
      amountCents: [],
    },
    isPersonalInfoInvalid: true,
    isAccountInfoInvalid: true,
    isPaymentAmountInvalid: true,
  });

  const getValidationMessages = (validationKey: FieldsToValidate) => {
    const validationMessages = formState.validationMessages;

    let x: never;
    switch (validationKey) {
      case "firstName":
        validationMessages.firstName =
          formState.party === "3RD-PARTY"
            ? getValidationMessagesFromSchema(
                formState.firstName,
                firstNameValidationSchema,
              )
            : [];
        break;
      case "lastName":
        validationMessages.lastName =
          formState.party === "3RD-PARTY"
            ? getValidationMessagesFromSchema(
                formState.lastName,
                lastNameValidationSchema,
              )
            : [];
        break;
      case "abaNumber":
        validationMessages.abaNumber = getValidationMessagesFromSchema(
          formState.abaNumber,
          routingNumberSchema,
        );
        break;
      case "ddaNumber":
        validationMessages.ddaNumber = getValidationMessagesFromSchema(
          formState.ddaNumber,
          accountNumberSchema,
        );
        break;
      case "amountCents":
        validationMessages.amountCents =
          getValidationMessagesForAmountCents(formState);
        break;
      default:
        x = validationKey;
        reportError(
          new Error(
            "Programmer Error: Invalid FieldsToValidate key when attempting to get local validation messages",
            {
              cause: validationKey,
            },
          ),
        );
        return x;
    }
    formDispatch((prevFormState) => {
      return { ...prevFormState, validationMessages: validationMessages };
    });
  };

  const clearValidationMessages = (validationKey: FieldsToValidate) => {
    const validationMessages = formState.validationMessages;

    let x: never;
    switch (validationKey) {
      case "firstName":
        validationMessages.firstName = [];
        break;
      case "lastName":
        validationMessages.lastName = [];
        break;
      case "abaNumber":
        validationMessages.abaNumber = [];
        break;
      case "ddaNumber":
        validationMessages.ddaNumber = [];
        break;
      case "amountCents":
        validationMessages.amountCents = [];
        break;
      default:
        x = validationKey;
        reportError(
          new Error(
            "Programmer Error: Invalid FieldsToValidate key when attempting to clear local validation messages",
            {
              cause: validationKey,
            },
          ),
        );
        return x;
    }
    formDispatch((prevFormState) => {
      return { ...prevFormState, validationMessages: validationMessages };
    });
  };

  const generalDispatch = (payload: Partial<FormState>) => {
    formDispatch((prevFormState) => {
      const newFormState = {
        ...prevFormState,
        ...payload,
      };

      return {
        ...newFormState,
        isComplete: isFormComplete(newFormState),
        isPersonalInfoInvalid: isPersonalInfoInvalid(newFormState),
        isAccountInfoInvalid: isAccountInfoInvalid(newFormState),
        isPaymentAmountInvalid: isPaymentAmountInvalid(newFormState),
      };
    });
  };

  return [
    formState,
    { generalDispatch, getValidationMessages, clearValidationMessages },
  ];
};
