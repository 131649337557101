import {
  DateString,
  PaymentAmountOptions,
  ScriptType,
} from "../../lib/api/models";
import {
  MOCK_ACCOUNT,
  MOCK_CLASSIFICATION_COUNT,
  MOCK_CYCLE_TO_DATE_TRANSACTIONS_RESPONSE,
  MOCK_GET_STATEMENT_HISTORY_RESPONSE,
  MOCK_PAYMENT_HISTORY_SHORT,
  MOCK_PAYMENT_METHODS,
  MOCK_PAYMENT_SERIES_SCHEDULE_RESPONSE,
  MOCK_PAYMENT_TRANSACTIONS,
  MOCK_PERMISSION_TO_DISCUSS_RESPONSE,
} from "../../lib/api/mocks/data.mock";
import PaymentSeriesTable, {
  PaymentSeriesScheduleRow,
} from "../../modules/MakePayment/PaymentSeriesSchedule/PaymentSeriesTable";
import { useEffect, useState } from "react";

import AddPermissionToDiscuss from "../../modules/PermissionToDiscuss/AddPermissionToDiscuss";
import AgentInitials from "../../modules/SecureMail/AgentInitials";
import ClassificationCard from "../../modules/SecureMailQueue/ClassificationCard";
import ClassificationList from "../../modules/SecureMailQueue/ClassificationList";
import ColoredLabelBox from "../../modules/common/ColoredLabelBox";
import ConversationScript from "../../modules/common/ConversationScript";
import CycleToDateTransactionsDeclinedTable from "../../modules/CycleToDateTransactions/CycleToDateTransactionsDeclinedTable";
import CycleToDateTransactionsPendingTable from "../../modules/CycleToDateTransactions/CycleToDateTransactionsPendingTable";
import CycleToDateTransactionsPostedTable from "../../modules/CycleToDateTransactions/CycleToDateTransactionsPostedTable";
import { DEFAULT_FILTER } from "../../modules/PaymentTransactions/hooks";
import DateInput from "../../modules/common/DateInput";
import DuplicatePaymentModal from "../../modules/MakePayment/DuplicatePaymentModal/DuplicatePaymentModal";
import EditPermissionToDiscuss from "../../modules/PermissionToDiscuss/EditPermissionToDiscuss";
import ExpirationDateInput from "../../modules/common/ExpirationDateInput";
import InfoValueTable from "../../modules/common/InfoValueTable";
import MakePaymentConfirmationModal from "../../modules/MakePayment/MakePaymentConfirmationModal";
import MessageBubble from "../../modules/SecureMail/MessageBubble";
import MessageInput from "../../modules/SecureMail/MessageInput";
import MessagesHeader from "../../modules/SecureMail/MessagesHeader";
import PaginationControl from "../../modules/SecureMailQueue/PaginationControl";
import PaymentAmountSelector from "../../modules/ManageAutopay/AddAutopay/PaymentAmountSelector";
import PaymentHistoryTable from "../../modules/PaymentHistory/PaymentHistoryTable";
import PaymentScriptsModal from "../../modules/PaymentScriptsModal";
import PaymentTransactionsTable from "../../modules/PaymentTransactions/PaymentTransactionsTable";
import PermissionToDiscussTable from "../../modules/PermissionToDiscuss/PermissionToDiscussTable";
import SecureMailTable from "../../modules/common/tables/SecureMailTable";
import Select from "../../modules/common/Select";
import SourceAccountSelect from "../../modules/MakePayment/SourceAccountSelect";
import SourceAccountsRow from "../../modules/SourceAccounts/SourceAccountsRow";
import SourceAccountsTable from "../../modules/SourceAccounts/SourceAccountsTable";
import Spinner from "../../modules/common/Spinner";
import StatementHistoryTable from "../../modules/StatementHistory/StatementHistoryTable";
import StyledButton from "../../modules/common/buttons/StyledButton";
import StyledCheckbox from "../../modules/common/StyledCheckbox";
import StyledInput from "../../modules/common/inputs/StyledInput";
import StyledLinkButton from "../../modules/common/StyledLinkButton";
import StyledNumPaymentsInput from "../../modules/MakePayment/StyledNumPaymentsInput";
import StyledPasswordInput from "../../modules/StyledPasswordInput";
import StyledRadioButton from "../../modules/StyledRadioButton";
import StyledZipcodeInput from "../../modules/common/StyledZipcodeInput";
import TextButton from "../../modules/common/buttons/TextButton";
import TextToggle from "../../modules/common/buttons/TextToggle";
import Toolbar from "../../modules/Toolbar";
import { centsToDollarString } from "../../lib/formatting";
/* eslint-disable  @typescript-eslint/no-empty-function */
import classNames from "classnames";
import { paths } from "../../lib/router";
import styles from "./DemoPage.module.css";
import { useBlocker } from "react-router-dom";
import { useToolState } from "../../modules/AccountDetails";
import {
  creditLineIncreaseScript01,
  creditLineIncreaseScript02,
  creditLineIncreaseScript03,
  creditLineIncreaseScript04,
  creditLineIncreaseScript05,
  creditLineIncreaseScript06,
  creditLineIncreaseScript07,
  creditLineIncreaseScript08,
  creditLineIncreaseScript09,
  creditLineIncreaseScript10,
  creditLineIncreaseScript11,
  creditLineIncreaseScript12,
  creditLineIncreaseScript13,
  creditLineIncreaseScript14,
  creditLineIncreaseScript15,
  creditLineIncreaseScript16,
  creditLineIncreaseScript17,
  creditLineIncreaseScript18,
  creditLineIncreaseScript19,
} from "../../lib/scripts/creditLineIncrease/creditLineIncreaseScripts";

type ClassificationType = "class_1" | "class_2" | "class_3";

const selectOptions: { label: string; value: ClassificationType }[] = [
  { label: "Classification 1", value: "class_1" },
  { label: "Classification 2", value: "class_2" },
  { label: "Classification 3", value: "class_3" },
];

const strings = {
  cardMember: "Card Member",
  enrollAutopay: "Enroll in Autopay",
  lorem: "Lorem Ipsum",
  removeAutopay: "Remove Autopay Enrollment",
  reopenTool: "Reopen Tool",
  thirdParty: "Third Party",
  validationMessage:
    "The Card Member's username must be between 8 and 16 characters, must start with a letter, and can only contain letters and numbers",
  thisIsLink: "Link Button to Account Search Page",
  paymentScriptsMessage:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere lorem ipsum dolor sit amet consectetur adipiscing. Ultricies lacus sed turpis tincidunt id. Sit amet mattis vulputate enim nulla aliquet porttitor lacus. Sagittis id consectetur purus ut faucibus pulvinar elementum. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu. In pellentesque massa placerat duis ultricies lacus sed. Eget mauris pharetra et ultrices neque ornare aenean euismod elementum. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo. Scelerisque eleifend donec pretium vulputate. Elementum integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan sit amet nulla facilisi morbi tempus iaculis urna id.",
};

const staticData = {
  infoValueTableData: [
    {
      label: "Account Number",
      value: "1234567890",
    },
    {
      label: "Card Number",
      value: "1234 5678 9012 3456",
    },
    {
      label: "Card Member",
      value: "John Doe",
    },
    {
      label: "Card Member Since",
      value: "01/01/2021",
    },
    {
      label: "Current Balance",
      value: "$1,234.56",
    },
  ],
};

const dummyPaginationObj = {
  forwardArrowPath: {
    pathname: "/",
    search: "",
  },
  backArrowPath: {
    pathname: "/",
    search: "",
  },
  paginationLinks: [
    {
      pathname: "/",
      search: "",
    },
    {
      pathname: "/",
      search: "",
    },
    {
      pathname: "/",
      search: "",
    },
    {
      pathname: "/",
      search: "",
    },
    {
      pathname: "/",
      search: "",
    },
  ],
};

function DemoPage() {
  const toolState = useToolState();
  const blocker = useBlocker(false);
  useEffect(() => {
    if (blocker.state === "blocked") {
      // TODO: Actual UX will be far better
      //       https://www.figma.com/file/MxdzlynPrWUK4FXZrS2Da3/1FB-Agent-Portal?type=design&node-id=2240-2551&mode=design&t=LsRVesDhHDJG0yBR-4
      if (window.confirm("You have unsaved work! Proceed?")) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  const [selectedSelectOption, setSelectedSelectOption] =
    useState<ClassificationType>("class_1");
  const [selectedOption, setSelectedOption] = useState<string>();
  const [message, setMessage] = useState<string>("");
  const [attachment, setAttachment] = useState<File | undefined>(undefined);
  const [paymentSeriesSchedule, setPaymentSeriesSchedule] = useState<
    PaymentSeriesScheduleRow[]
  >(
    MOCK_PAYMENT_SERIES_SCHEDULE_RESPONSE.schedule.map((payment) => ({
      paymentAmount: centsToDollarString(payment.amountCents),
      paymentDate: payment.date as DateString,
      checked: true,
    })),
  );

  const [inputValue, setInputValue] = useState("");

  const [selectedPaymentAmount, setSelectedPaymentAmount] =
    useState<PaymentAmountOptions>();

  const [currentIndex] = useState(1);
  const [customAmount, onSetCustomAmount] = useState("");
  const [customAmount2, onSetCustomAmount2] = useState("xyz");
  const [dateString, setDateString] = useState<DateString>("");
  const [radioToggled, setRadioToggled] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showDupPayModal, setShowDupPayModal] = useState(false);
  const [
    showMakePaymentConfirmationModal,
    setShowMakePaymentConfirmationModal,
  ] = useState(false);
  const [selectedSourceAccount, setSelectedSourceAccount] =
    useState<string>("");
  const [expDate, setExpDate] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [numPayments, setNumPayments] = useState(1);

  const [paymentScriptsMessage, setPaymentScriptsMessage] = useState("");

  return (
    <main>
      <div className={styles.container}>
        <Toolbar
          account={MOCK_ACCOUNT}
          toolState={toolState}
          scrollToCompleted={() => {}}
        />
        <div className={styles.mainContent}>
          <h1>Demo Page</h1>
          <div className={styles.componentDemoContainer}>
            <div className={styles.inputSelectContainer}>
              <StyledInput
                label="First Name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Select
                options={selectOptions}
                selectedValue={selectedSelectOption}
                onChange={setSelectedSelectOption}
                label="Select"
              />
            </div>
            <StyledInput
              label="First Name"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <StyledInput
              className={styles.input}
              label="Last Name"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={true}
            />
            <StyledInput
              className={styles.input}
              label="Initials"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              validationMessages={[strings.validationMessage]}
            />
            <StyledPasswordInput
              className={styles.input}
              label="Password"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <StyledPasswordInput
              className={styles.input}
              label="Password Invalid"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              validationMessages={[strings.validationMessage]}
            />
            <div className={styles.styledButtonContainer}>
              <StyledButton className={styles.enrollButton}>
                {strings.enrollAutopay}
              </StyledButton>
              <StyledButton className={styles.enrollButton} disabled={true}>
                {strings.enrollAutopay}
              </StyledButton>
              <StyledButton className={styles.enrollButton} isLoading>
                {strings.enrollAutopay}
              </StyledButton>
            </div>
            <div className={styles.styledButtonContainer}>
              <StyledButton className={styles.enrollButton} variant="secondary">
                {strings.reopenTool}
              </StyledButton>
              <StyledButton
                className={styles.enrollButton}
                variant="secondary"
                disabled={true}
              >
                {strings.reopenTool}
              </StyledButton>
              <StyledButton
                className={styles.enrollButton}
                variant="secondary"
                isLoading
              >
                {strings.reopenTool}
              </StyledButton>
            </div>
            <div className={styles.styledButtonContainer}>
              <StyledButton className={styles.enrollButton} variant="danger">
                {strings.removeAutopay}
              </StyledButton>
              <StyledButton
                className={styles.enrollButton}
                variant="danger"
                disabled={true}
              >
                {strings.removeAutopay}
              </StyledButton>
              <StyledButton
                className={styles.enrollButton}
                variant="danger"
                isLoading
              >
                {strings.removeAutopay}
              </StyledButton>
            </div>
            <div className={styles.textButtonContainer}>
              <TextButton>{strings.lorem}</TextButton>
              <TextButton className={styles.textButton} disabled={true}>
                {strings.lorem}
              </TextButton>
            </div>
            <div className={styles.styledButtonContainer}>
              <StyledLinkButton
                className={classNames(styles.enrollButton, styles.link)}
                to={paths.accountSearch}
              >
                {strings.thisIsLink}
              </StyledLinkButton>
            </div>
            <TextToggle
              className={styles.textToggle}
              options={[
                { key: "CM", label: strings.cardMember },
                { key: "3RD_PARTY", label: strings.thirdParty },
              ]}
              onSelectOption={(key) => setSelectedOption(key)}
              selectedOption={selectedOption}
            />
            <TextToggle
              className={styles.textToggle}
              options={[
                { key: "CM", label: strings.cardMember },
                { key: "3RD_PARTY", label: strings.thirdParty },
              ]}
              onSelectOption={(key) => setSelectedOption(key)}
              selectedOption={selectedOption}
              disabled={true}
            />
            <div className={styles.infoValueTableContainer}>
              <InfoValueTable
                headerType="large"
                title="Large Header"
                subtitle="Subtitle"
                rightText="Right Text"
                data={staticData.infoValueTableData}
              />
              <InfoValueTable
                headerType="small"
                title="Small Header"
                data={staticData.infoValueTableData}
              />
              <InfoValueTable
                headerType="none"
                data={staticData.infoValueTableData}
              />
            </div>
            <PaymentAmountSelector
              selectedPaymentAmount={selectedPaymentAmount}
              onSelectPaymentAmount={setSelectedPaymentAmount}
              customInput={customAmount}
              onChangeCustomInput={onSetCustomAmount}
              className={styles.selector}
              onBlurCustomInput={() => {}}
            />
            <PaymentAmountSelector
              selectedPaymentAmount={selectedPaymentAmount}
              onSelectPaymentAmount={setSelectedPaymentAmount}
              customInput={customAmount2}
              onChangeCustomInput={onSetCustomAmount2}
              className={styles.selector}
              onBlurCustomInput={() => {}}
            />
            <div className={styles.paymentTransactionsTableContainer}>
              <h2>AgentInitials Component</h2>
              <AgentInitials firstName={"Alexis"} lastName={"Kaufman"} />
              <br />
              <h2>Spinner</h2>
              <Spinner />
              <h2>MessageBubble from Employee</h2>
              <MessageBubble
                timestamp={new Date()}
                message={
                  "Thank you for your credit line increase inquiry. We are reviewing your account and will have a response for you soon."
                }
                dwbuid={"4535237234"}
                isEmployee={true}
                employeeFirstName={"Owen"}
                employeeLastName={"Powers"}
                timeRead={new Date()}
                onAttachmentClick={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <h2>MessageBubble from Customer</h2>
              <MessageBubble
                timestamp={new Date()}
                message={
                  "I've made timely payments for the last 6 months and hope my responsible card usage supports my case for an increase.              "
                }
                dwbuid={"4535237234"}
                isEmployee={false}
                employeeFirstName={"Owen"}
                employeeLastName={"Powers"}
                timeRead={null}
                onAttachmentClick={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <h2>MessageBubble with Attachments</h2>
              <MessageBubble
                timestamp={new Date()}
                message={"Here are the files you asked for."}
                dwbuid={"4535237234"}
                isEmployee={false}
                employeeFirstName={"Alexis"}
                employeeLastName={"Kaufman"}
                attachments={[
                  {
                    id: "2",
                    name: "screenshot_1.jpg",
                    mimeType: "image/jpeg",
                  },
                  {
                    id: "3",
                    name: "screenshot_2.jpg",
                    mimeType: "image/jpeg",
                  },
                ]}
                timeRead={null}
                onAttachmentClick={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <h2>MessageBubble with Disabled Attachments</h2>
              <MessageBubble
                timestamp={new Date()}
                message={"Here are the files you asked for."}
                dwbuid={"4535237234"}
                isEmployee={false}
                employeeFirstName={"Alexis"}
                employeeLastName={"Kaufman"}
                attachmentButtonDisabled={true}
                attachments={[
                  {
                    id: "2",
                    name: "screenshot_1.jpg",
                    mimeType: "image/jpeg",
                  },
                  {
                    id: "3",
                    name: "screenshot_2.jpg",
                    mimeType: "image/jpeg",
                  },
                ]}
                timeRead={null}
                onAttachmentClick={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <h2>MessageInput</h2>
              <MessageInput
                message={message}
                attachment={attachment}
                onChangeMessage={setMessage}
                onSend={() => null}
                onAttachment={setAttachment}
                isSendMessageLoading={false}
              />
              <h2>Loading MessageInput</h2>
              <MessageInput
                message={message}
                attachment={undefined}
                onChangeMessage={setMessage}
                onSend={() => null}
                onAttachment={() => null}
                isSendMessageLoading={true}
              />
              <h2>MessagesHeader</h2>
              <MessagesHeader
                employees={[
                  {
                    employeeId: "3453264752",
                    firstname: "Aaron",
                    lastname: "David",
                  },
                  {
                    employeeId: "2134234",
                    firstname: "Tammy",
                    lastname: "Lee",
                  },
                  {
                    employeeId: "947272",
                    firstname: "Ornold",
                    lastname: "Palmer",
                  },
                ]}
                dwbuid={"123456"}
                subject={"Message Subject"}
                customerFirstName="Annie"
                customerLastName="Belle"
              />
              <h2>Select</h2>
              <div
                style={{
                  paddingBottom: "60px",
                  paddingTop: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Select
                  className={styles.select}
                  options={selectOptions}
                  selectedValue={selectedSelectOption}
                  onChange={setSelectedSelectOption}
                  ariaLabel="demo-select"
                />
              </div>
              <h2>PaymentHistoryTable</h2>
              <PaymentHistoryTable history={MOCK_PAYMENT_HISTORY_SHORT} />
              <h2>PaymentTransactionsTable</h2>
              <PaymentTransactionsTable
                filter={DEFAULT_FILTER}
                transactions={MOCK_PAYMENT_TRANSACTIONS.paymentTransactions}
                openScheduledPayment={() => {}}
                activeTools={[]}
                account={MOCK_ACCOUNT}
                onDisplayGeneralErrors={() => {
                  throw new Error("Function not implemented.");
                }}
              />
              <h2>PaymentSeriesTable</h2>
              <PaymentSeriesTable
                paymentSeriesSchedule={paymentSeriesSchedule}
                setPaymentSeriesSchedule={setPaymentSeriesSchedule}
                isEditSeries={false}
              />
              <h2>ClassificationCard</h2>
              <ClassificationCard
                id={"0"}
                title="Classification Card"
                numberOfItems={10}
                selected={true}
                to={{ pathname: "/" }}
              />
              <ClassificationCard
                id={"1"}
                title="Classification Card"
                numberOfItems={10}
                selected={false}
                to={{ pathname: "/" }}
              />
              <h2>ClassificationList</h2>
              <ClassificationList
                classifications={MOCK_CLASSIFICATION_COUNT.classifications}
                selectedClassificationId="3"
              />
              <h2>PaginationControl</h2>
              <PaginationControl
                paginationObj={dummyPaginationObj}
                numPages={10}
              />
              <h2>ColoredLabelBox</h2>
              <div className={styles.flexRow}>
                <ColoredLabelBox status="danger">Danger</ColoredLabelBox>
                <ColoredLabelBox status="processed">Success</ColoredLabelBox>
                <ColoredLabelBox status="pending">Pending</ColoredLabelBox>
              </div>
              <h2 className={styles.section}>SecureMailTable - Normal</h2>
              <SecureMailTable
                className={styles.component}
                variant={"classification"}
                secureMail={{
                  total: 50,
                  secureMail: [
                    {
                      dwbuid: "1",
                      subject: `${currentIndex} A Forgot password`,
                      updateDate: new Date(),
                      type: "NEW",
                      secureMailId: "be921666-98c2-422e-af05-b53c116312c",
                    },
                    {
                      dwbuid: "2",
                      subject: `${currentIndex} B Login issues`,
                      updateDate: new Date(),
                      type: "OPEN",
                      secureMailId: "f164ca82-cfde-45b1-8c63-61b6e9b261a2",
                    },
                    {
                      dwbuid: "3",
                      subject: `${currentIndex} C Update card information`,
                      updateDate: new Date(),
                      type: "READONLY",
                      secureMailId: "10c38deb-2096-42ac-8874-587d5a64f7e0",
                    },
                    {
                      dwbuid: "4",
                      subject: `${currentIndex} D Update card information`,
                      updateDate: new Date(),
                      type: "OPEN",
                      secureMailId: "c05070c0-570f-409b-a629-3dedd1d5cc90",
                    },
                    {
                      dwbuid: "5",
                      subject: `${currentIndex} E Update card information`,
                      updateDate: new Date(),
                      type: "NEW",
                      secureMailId: "2266964d-17e6-49d7-b8e1-4a4567e8fb3a",
                    },
                  ],
                }}
                pageSize={5}
                paginationObj={dummyPaginationObj}
              />
              <h2 className={styles.section}>SecureMailTable - Account</h2>
              <SecureMailTable
                className={styles.component}
                variant={"cardmember"}
                secureMail={{
                  total: 50,
                  secureMail: [
                    {
                      dwbuid: "1",
                      subject: `${currentIndex} A Forgot password`,
                      updateDate: new Date(),
                      type: "NEW",
                      secureMailId: "be921666-98c2-422e-af05-b53c116312c",
                    },
                    {
                      dwbuid: "2",
                      subject: `${currentIndex} B Login issues`,
                      updateDate: new Date(),
                      type: "OPEN",
                      secureMailId: "f164ca82-cfde-45b1-8c63-61b6e9b261a2",
                    },
                    {
                      dwbuid: "3",
                      subject: `${currentIndex} C Update card information`,
                      updateDate: new Date(),
                      type: "READONLY",
                      secureMailId: "10c38deb-2096-42ac-8874-587d5a64f7e0",
                    },
                    {
                      dwbuid: "4",
                      subject: `${currentIndex} D Update card information`,
                      updateDate: new Date(),
                      type: "OPEN",
                      secureMailId: "c05070c0-570f-409b-a629-3dedd1d5cc90",
                    },
                    {
                      dwbuid: "5",
                      subject: `${currentIndex} E Update card information`,
                      updateDate: new Date(),
                      type: "NEW",
                      secureMailId: "2266964d-17e6-49d7-b8e1-4a4567e8fb3a",
                    },
                  ],
                }}
                pageSize={5}
                paginationObj={dummyPaginationObj}
              />
            </div>
            <h2>DateInput</h2>
            <DateInput
              label="Start Date"
              value={dateString}
              onChangeDate={setDateString}
            />
            <h2>StyledRadioButton</h2>
            <StyledRadioButton
              label="ACH"
              checked={radioToggled}
              onChange={(v) => setRadioToggled(v.target.checked)}
            />
            <h2>StyledCheckbox</h2>
            <StyledCheckbox
              label="Remove"
              checked={checkboxChecked}
              onChange={(e) => setCheckboxChecked(e.target.checked)}
            />
            <h2>SourceAccountsRow</h2>
            <SourceAccountsRow
              onEdit={() => {}}
              account={MOCK_PAYMENT_METHODS.cardMemberAccounts[0]}
            />
            <SourceAccountsRow
              onEdit={() => {}}
              account={MOCK_PAYMENT_METHODS.cardMemberAccounts[1]}
            />
            <SourceAccountsRow
              onEdit={() => {}}
              account={MOCK_PAYMENT_METHODS.cardMemberAccounts[2]}
            />
            <SourceAccountsRow
              onEdit={() => {}}
              account={MOCK_PAYMENT_METHODS.cardMemberAccounts[3]}
            />
            <h2>SourceAccountsTable</h2>
            <div className={styles.sourceAccountsTables}>
              <SourceAccountsTable
                className={classNames(
                  styles.sourceAccountsTable,
                  styles.cardmemberAccountsTable,
                )}
                type="cardmember"
                onEdit={() => {}}
                accounts={MOCK_PAYMENT_METHODS.thirdPartyAccounts.filter(
                  (a) => a.is1FBAccount,
                )}
              />
              <SourceAccountsTable
                className={styles.sourceAccountsTable}
                type="third_party"
                onEdit={() => {}}
                accounts={MOCK_PAYMENT_METHODS.cardMemberAccounts.filter(
                  (a) => !a.is1FBAccount,
                )}
              />
            </div>
            <h2>Duplicate Payment Modal</h2>
            <button onClick={() => setShowDupPayModal(true)}>
              Show duplicate payment modal
            </button>
            <DuplicatePaymentModal
              isOpen={showDupPayModal}
              onRequestClose={() => setShowDupPayModal(false)}
              onConfirm={() => {}}
              scheduledPayments={[
                {
                  id: "100",
                  amountCents: 0,
                  scheduledPaymentDate: "2023-10-12",
                  confirmationId: "100",
                  paymentAmount: "100.00",
                  paymentMethod: { id: "1000", name: "Visa" },
                  status: "done",
                },
                {
                  id: "101",
                  amountCents: 30000,
                  scheduledPaymentDate: "2023-10-14",
                  confirmationId: "101",
                  paymentAmount: "100.00",
                  paymentMethod: { id: "1000", name: "Visa" },
                  status: "done",
                },
              ]}
            />
            <h2>Make Payment Confirmation Modal</h2>
            <button onClick={() => setShowMakePaymentConfirmationModal(true)}>
              Show Make Payment Confirmation Modal
            </button>
            <MakePaymentConfirmationModal
              isOpen={showMakePaymentConfirmationModal}
              onRequestClose={() => setShowMakePaymentConfirmationModal(false)}
              onComplete={() => {}}
            />
            <div className={styles.select}>
              <h2>SourceAccountSelect</h2>
              <SourceAccountSelect
                selectedValue={selectedSourceAccount}
                onChange={(v) => setSelectedSourceAccount(v)}
                onAddNew={() => {}}
                accounts={MOCK_PAYMENT_METHODS.cardMemberAccounts}
              />
            </div>
            <h2>StyledNumPaymentsInput</h2>
            <StyledNumPaymentsInput
              className={styles.numPayments}
              label="# of Payments"
              value={numPayments}
              onChange={(e) => setNumPayments(parseInt(e.target.value))}
              max={24}
            />
            <h2>ExpirationDateInput</h2>
            <ExpirationDateInput
              className={styles.expDate}
              label="Expiration Date"
              value={expDate}
              onChange={setExpDate}
            />
            <h2>StyledZipcodeInput</h2>
            <StyledZipcodeInput
              className={styles.zipcode}
              value={zipcode}
              onChange={setZipcode}
              label="Zip"
            />
            <h2>Payment Scripts Modal</h2>
            <StyledButton
              onClick={() =>
                setPaymentScriptsMessage(strings.paymentScriptsMessage)
              }
            >
              Open Payment Scripts Modal
            </StyledButton>
            <PaymentScriptsModal
              message={paymentScriptsMessage}
              onSubmit={() => setPaymentScriptsMessage("")}
              onDecline={() => setPaymentScriptsMessage("")}
            />
            <h2>Permission To Discuss Table</h2>
            <PermissionToDiscussTable
              permissions={MOCK_PERMISSION_TO_DISCUSS_RESPONSE.permissionsList}
              onEdit={() => {}}
              onRemove={() => {}}
            />
            <h2>Permission to Discuss Add</h2>
            <AddPermissionToDiscuss
              onSubmit={() => new Promise<unknown>(() => null)}
              onCancel={() => {}}
              relationshipTypesById={{
                ["1"]: { id: "1", relationship: "Employer" },
                ["2"]: { id: "2", relationship: "Brother" },
                ["3"]: { id: "3", relationship: "Friend" },
              }}
              isSubmitting={false}
            />
            <h2>Permission to Discuss Edit</h2>
            <EditPermissionToDiscuss
              onSubmit={() => new Promise<unknown>(() => null)}
              onCancel={() => {}}
              relationshipTypesById={{
                ["1"]: { id: "1", relationship: "Employer" },
                ["2"]: { id: "2", relationship: "Brother" },
                ["3"]: { id: "3", relationship: "Friend" },
              }}
              editableData={{
                id: "1004",
                agent: "MGM",
                channel: "RES",
                date: "2024-02-15 13:29:00",
                effectiveDate: "2024-02-15",
                firstName: "Bob",
                lastName: "Bobbers",
                relation: {
                  id: "cbfc0a0c-4003-4c57-ad15-7c5020201cf6",
                  relationship: "Employer",
                },
                source: "Affinity",
                status: "active",
              }}
              isSubmitting={false}
            />
            <h2>Cycle to Date Transactions Posted Table</h2>
            <CycleToDateTransactionsPostedTable
              transactions={
                MOCK_CYCLE_TO_DATE_TRANSACTIONS_RESPONSE.postedTransactions
              }
            />
            <h2>Cycle to Date Transactions Pending Table</h2>
            <CycleToDateTransactionsPendingTable
              transactions={
                MOCK_CYCLE_TO_DATE_TRANSACTIONS_RESPONSE.pendingAuthorizations
              }
            />
            <h2>Cycle to Date Transactions Declined Table</h2>
            <CycleToDateTransactionsDeclinedTable
              transactions={
                MOCK_CYCLE_TO_DATE_TRANSACTIONS_RESPONSE.declinedAuthorizations
              }
            />
            <h2>Statement History Table</h2>
            <div className={styles.statementHistoryContainer}>
              <StatementHistoryTable
                statements={MOCK_GET_STATEMENT_HISTORY_RESPONSE.statements}
              />
            </div>
            <h2>Scripting</h2>
            <div id="conversation-script">
              <ConversationScript
                scriptName="CLI00"
                scripts={[
                  {
                    instructions: [
                      {
                        text: "Complete a Full Account Verification and an Out of Wallet question if not completed at the beginning of the call.",
                      },
                    ],
                    scriptType: ScriptType.Instruction,
                  },
                  {
                    dialogue: "I can help you process the lost report",
                    scriptType: ScriptType.Dialogue,
                  },
                  {
                    dialogue:
                      "Are you aware of any fraud charges on the account?",
                    scriptType: ScriptType.Dialogue,
                  },
                  {
                    instructions: [
                      {
                        text: `Review charges and authorizations on the account.\nThen select the appropriate "Fraud on Account" Radio button\n\n`,
                      },
                      {
                        text: "If NO Fraud, ",
                        styleOptions: ["bold"],
                      },
                      {
                        text: `Select No to "Fraud on Account?" AND Continue with Lost Report\n\n`,
                      },
                      {
                        text: "If Fraud, ",
                        styleOptions: ["bold"],
                      },
                      {
                        text: `Select Yes to "Fraud on Account?"`,
                      },
                    ],
                    scriptType: ScriptType.Instruction,
                  },
                  {
                    dialogue:
                      "Please hold on the line while I transfer you to an account specialist that can assist you.",
                    scriptType: ScriptType.Dialogue,
                  },
                  {
                    instructions: [
                      {
                        text: 'If Transfer to Security 6777 was successful\nSelect Yes to "Xfer\'d to Security 6777?"',
                      },
                    ],
                    scriptType: ScriptType.Instruction,
                  },
                ]}
              />
              <h3>CLI Script 01</h3>
              <ConversationScript
                scriptName="CLI01"
                scripts={creditLineIncreaseScript01("$750")}
              />
              <h3>CLI Script 02</h3>
              <ConversationScript
                scriptName="CLI02"
                scripts={creditLineIncreaseScript02}
              />
              <h3>CLI Script 03</h3>
              <ConversationScript
                scriptName="CLI03"
                scripts={creditLineIncreaseScript03}
              />
              <h3>CLI Script 04</h3>
              <ConversationScript
                scriptName="CLI04"
                scripts={creditLineIncreaseScript04}
              />
              <h3>CLI Script 05</h3>
              <ConversationScript
                scriptName="CLI05"
                scripts={creditLineIncreaseScript05}
              />
              <h3>CLI Script 06</h3>
              <ConversationScript
                scriptName="CLI06"
                scripts={creditLineIncreaseScript06}
              />
              <h3>CLI Script 07</h3>
              <ConversationScript
                scriptName="CLI07"
                scripts={creditLineIncreaseScript07}
              />
              <h3>CLI Script 08</h3>
              <ConversationScript
                scriptName="CLI08"
                scripts={creditLineIncreaseScript08}
              />
              <h3>CLI Script 09</h3>
              <ConversationScript
                scriptName="CLI09"
                scripts={creditLineIncreaseScript09}
              />
              <h3>CLI Script 10</h3>
              <ConversationScript
                scriptName="CLI10"
                scripts={creditLineIncreaseScript10}
              />
              <h3>CLI Script 11</h3>
              <ConversationScript
                scriptName="CLI11"
                scripts={creditLineIncreaseScript11}
              />
              <h3>CLI Script 12</h3>
              <ConversationScript
                scriptName="CLI12"
                scripts={creditLineIncreaseScript12("$1000")}
              />
              <h3>CLI Script 13</h3>
              <ConversationScript
                scriptName="CLI13"
                scripts={creditLineIncreaseScript13}
              />
              <h3>CLI Script 14</h3>
              <ConversationScript
                scriptName="CLI14"
                scripts={creditLineIncreaseScript14}
              />
              <h3>CLI Script 15</h3>
              <ConversationScript
                scriptName="CLI15"
                scripts={creditLineIncreaseScript15}
              />
              <h3>CLI Script 16</h3>
              <ConversationScript
                scriptName="CLI16"
                scripts={creditLineIncreaseScript16}
              />
              <h3>CLI Script 17</h3>
              <ConversationScript
                scriptName="CLI17"
                scripts={creditLineIncreaseScript17}
              />
              <h3>CLI Script 18</h3>
              <ConversationScript
                scriptName="CLI18"
                scripts={creditLineIncreaseScript18}
              />
              <h3>CLI Script 19</h3>
              <ConversationScript
                scriptName="CLI19"
                scripts={creditLineIncreaseScript19}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DemoPage;
