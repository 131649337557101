import classNames from "classnames";
import styles from "./Autopay.module.css";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import { Col, Container, Row } from "react-grid-system";
import StyledButton from "../common/buttons/StyledButton";
import Subtool from "../common/Subtool";
import PaymentAmountSelector from "../ManageAutopay/AddAutopay/PaymentAmountSelector";

const strings = {
  close: "Close",
  one: "1",
  two: "2",
  holderAndAccountInfoSubheader: "Holder and Account Info",
  amountSubheader: "Amount",
  enroll: "Enroll in Autopay",
};

type Props = {
  toolId: string;
  onRequestClose: () => void;
  className?: string;
};

export default function Autopay({ toolId, onRequestClose, className }: Props) {
  // TODO: Rename to Manage Autopay when previous manage autopay fully replaced

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col md={11} className={styles.centerCol}>
            <h2 className={styles.header}>
              {toolTypeToName(ToolType.Autopay)}
            </h2>
          </Col>
          <Col md={1} className={styles.centerCol}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Subtool>
              <div className={styles.subheaderContainer}>
                <div className={styles.subtoolNumberOne}>{strings.one}</div>
                <div className={styles.subheader}>
                  {strings.holderAndAccountInfoSubheader}
                </div>
              </div>
            </Subtool>
          </Col>
          <Col md={4}>
            <Subtool>
              <div className={styles.subheaderContainer}>
                <div className={styles.subtoolNumberTwo}>{strings.two}</div>
                <div className={styles.subheader}>
                  {strings.amountSubheader}
                </div>
              </div>
              <form onSubmit={() => null}>
                <PaymentAmountSelector
                  customInput=""
                  onSelectPaymentAmount={() => null}
                  onChangeCustomInput={() => null}
                  className={styles.selector}
                  onBlurCustomInput={() => null}
                />
                <StyledButton
                  className={styles.enrollmentButton}
                  variant="primary"
                  type="submit"
                >
                  {strings.enroll}
                </StyledButton>
              </form>
            </Subtool>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
