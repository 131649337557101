import { EnrollmentInfo, GetAccountResponse } from "../../../../lib/api/models";
import { convertDateStringToMonthDayYearFomat } from "../../../../lib/formatting";
import Subtool from "../../../common/Subtool";
import styles from "./ActiveEnrollmentDetails.module.css";

const strings = {
  enrolledDate: "EnrolledDate",
  abaNumber: "ABA Number",
  amount: "Amount",
  ddaNumber: "DDA Number",
  enrolled: "Enrolled",
  party: "Party",
  subheader: (dwbuid: string) => `Account ${dwbuid}`,
};

type Props = {
  account: GetAccountResponse;
  enrollmentInfo: EnrollmentInfo;
  enrolledDate: string | null | undefined;
};

export function ActiveEnrollmentDetails({
  account,
  enrollmentInfo,
  enrolledDate,
}: Props) {
  return (
    <Subtool className={styles.container}>
      <span className={styles.subheader}>
        {strings.subheader(account.currentDwbuid)}
      </span>
      <div className={styles.enrollmentStatus}>{strings.enrolled}</div>
      {enrolledDate ? (
        <div className={styles.labeledValue}>
          <div className={styles.label}>{strings.enrolledDate}</div>
          <div className={styles.value}>
            {convertDateStringToMonthDayYearFomat(enrolledDate)}
          </div>
        </div>
      ) : null}
      <div className={styles.labeledValue}>
        <div className={styles.label}>{strings.abaNumber}</div>
        <div className={styles.value}>{enrollmentInfo.abaNumber}</div>
      </div>
      <div className={styles.labeledValue}>
        <div className={styles.label}>{strings.ddaNumber}</div>
        <div className={styles.value}>{enrollmentInfo.ddaNumber}</div>
      </div>
      <div className={styles.labeledValue}>
        <div className={styles.label}>{strings.amount}</div>
        <div className={styles.value}>{enrollmentInfo.amount}</div>
      </div>
      {enrollmentInfo.party ? (
        <div className={styles.labeledValue}>
          <div className={styles.label}>{strings.party}</div>
          <div className={styles.value}>{enrollmentInfo.party}</div>
        </div>
      ) : null}
    </Subtool>
  );
}
