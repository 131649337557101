import classNames from "classnames";
import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  CreditLineIncreaseScript,
  GetAccountResponse,
} from "../../lib/api/models";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import CustomAmountInput from "../common/CustomAmountInput";
import PhoneNumberInput from "../common/PhoneNumberInput";
import Select from "../common/Select";
import StyledButton from "../common/buttons/StyledButton";
import {
  useGetCreditLineIncreaseEligibility,
  useSubmitCreditLineIncrease,
} from "../../lib/api/queries";
import Spinner from "../common/Spinner";
import StyledError from "../StyledError";
import { centsToDollarString, dollarStringToCents } from "../../lib/formatting";
import {
  creditLineIncreaseContactPhoneSchema,
  creditLineIncreaseNewCreditLimitSchema,
  creditLineIncreaseReasonSchema,
} from "../../lib/validation/schemas";
import { ZodError } from "zod";
import { NetworkErrorModal } from "../common/modals";
import { getDisplayError } from "../../lib/api/endpoints";
import { getCreditLineIncreaseScriptDetails } from "../../lib/scripts/creditLineIncrease/creditLineIncreaseScripts";
import ConversationScript from "../common/ConversationScript";
import styles from "./CreditLineIncrease.module.css";

const getNewCreditLimitValidationMessages = (
  currCreditLimit: string,
  maxCurrCreditLimit: string,
  newCreditLimit: string,
): string[] | undefined => {
  try {
    creditLineIncreaseNewCreditLimitSchema.parse({
      currCreditLimit,
      maxCurrCreditLimit,
      newCreditLimit,
    });
  } catch (err) {
    if (err instanceof ZodError && err.errors.length > 0) {
      return err.errors.map((vm) => vm.message);
    }
  }
};

const getReasonIdValidationMessages = (value: string): string[] | undefined => {
  try {
    creditLineIncreaseReasonSchema.parse(value);
  } catch (err) {
    if (err instanceof ZodError && err.errors.length > 0) {
      return err.errors.map((vm) => vm.message);
    }
  }
};

const getPhoneNumberValidationMessages = (
  value: string,
): string[] | undefined => {
  try {
    creditLineIncreaseContactPhoneSchema.parse(value);
  } catch (err) {
    if (err instanceof ZodError && err.errors.length > 0) {
      return err.errors.map((vm) => vm.message);
    }
  }
};

const renderScriptMessage = (
  rejectionReason: string | null,
  message?: string,
) => {
  let scriptMessage = rejectionReason ?? undefined;
  if (!scriptMessage || scriptMessage.length < 1) {
    scriptMessage = message;
    if (!scriptMessage || scriptMessage.length < 1) {
      return null;
    }
  }

  return <div className={styles.scriptMessage}>{scriptMessage}</div>;
};

const strings = {
  close: "Close",
  newCreditLimit: "New Credit Limit",
  reason: "Reason",
  contactPhoneNumber: "Contact Phone #",
  creditLineIncrease: (amount: string) =>
    `Eligible for Credit Line Increase of ${amount}`,
  maxNewCreditLine: (maxAmount: string) =>
    `Maximum New Credit Limit ${maxAmount}`,
  select: "Select...",
  submitCLIRequest: "Submit CLI Request",
  errorTitle: "Failed to load Credit Line Increase Eligiblity Requirements",
  cliSubmitRestriction:
    "Only one CLI transaction can be submitted per account search.",
};

type FormInput = {
  value: string;
  isValid: boolean;
  validationMessages?: string[];
};

type CreditLineFormState = {
  newLimitCents: FormInput;
  reasonId: FormInput;
  phoneNumber: FormInput;
};

const initialState: CreditLineFormState = {
  newLimitCents: {
    value: "",
    isValid: false,
    validationMessages: undefined,
  },
  reasonId: {
    value: "",
    isValid: false,
    validationMessages: undefined,
  },
  phoneNumber: {
    value: "",
    isValid: true,
    validationMessages: undefined,
  },
};

type Props = {
  account: GetAccountResponse;
  className?: string;
  toolId: string;
  onRequestClose: () => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  referenceId?: string;
  hasSubmittedCreditLineIncreaseThisSession: boolean;
  onSubmitCreditLineIncrease: () => void;
};

export default function CreditLineIncrease({
  account,
  className,
  toolId,
  onRequestClose,
  onSetToolInProgress,
  referenceId = "",
  hasSubmittedCreditLineIncreaseThisSession,
  onSubmitCreditLineIncrease,
}: Props) {
  const [formState, setFormState] = useState<CreditLineFormState>(initialState);
  const [displayError, setDisplayError] = useState("");
  const [hasFormBeenResubmitted, setHasFormBeenResubmitted] = useState(false);

  const {
    data: getCreditLineIncreaseEligibilityResponse,
    isLoading: isGetCreditLineIncreaseEligibilityResponseLoading,
    isError: isGetCreditLineIncreaseEligibilityResponseError,
    error: getCreditLineIncreaseEligibilityResponseError,
    refetch: getCreditLineIncreaseEligiblityResponseRefetch,
  } = useGetCreditLineIncreaseEligibility({
    dwbuid: account.currentDwbuid,
    referenceId,
  });

  const {
    mutateAsync: submitCreditLineIncrease,
    data: submitCreditLineIncreaseResponse,
    isLoading: isSubmitting,
  } = useSubmitCreditLineIncrease();

  useEffect(() => {
    if (hasFormBeenResubmitted || submitCreditLineIncreaseResponse) {
      onSetToolInProgress(toolId, false);
      return;
    }

    if (Object.values(formState).some((v) => !!v.value)) {
      onSetToolInProgress(toolId, true);
    } else {
      onSetToolInProgress(toolId, false);
    }
  }, [
    formState,
    onSetToolInProgress,
    hasFormBeenResubmitted,
    toolId,
    submitCreditLineIncreaseResponse,
  ]);

  const isSubmitDisabled =
    Object.values(formState).some((v) => !v.isValid) || isSubmitting;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    if (hasSubmittedCreditLineIncreaseThisSession) {
      setHasFormBeenResubmitted(true);
      setDisplayError(strings.cliSubmitRestriction);
      return;
    }

    submitCreditLineIncrease({
      dwbuid: account.currentDwbuid,
      newLimitCents: dollarStringToCents(formState.newLimitCents.value),
      reasonId: formState.reasonId.value,
      phoneNumber: formState.phoneNumber.value,
      referenceId,
    })
      .then(onSubmitCreditLineIncrease)
      .catch((err) => setDisplayError(getDisplayError(err)));
  };

  const scriptDetails = useMemo(() => {
    if (getCreditLineIncreaseEligibilityResponse) {
      let scriptName: CreditLineIncreaseScript | undefined = undefined;
      const hasFormBeenSubmitted = !!submitCreditLineIncreaseResponse;
      if (hasSubmittedCreditLineIncreaseThisSession && !hasFormBeenSubmitted) {
        scriptName = "CLI20";
      } else if (
        hasSubmittedCreditLineIncreaseThisSession &&
        hasFormBeenResubmitted
      ) {
        scriptName = "CLI20";
      } else {
        scriptName = submitCreditLineIncreaseResponse
          ? submitCreditLineIncreaseResponse.scriptName
          : getCreditLineIncreaseEligibilityResponse.scriptName;
      }

      return getCreditLineIncreaseScriptDetails(
        getCreditLineIncreaseEligibilityResponse.userData,
        scriptName,
        getCreditLineIncreaseEligibilityResponse.maxCreditLineCents,
      );
    }

    return undefined;
  }, [
    getCreditLineIncreaseEligibilityResponse,
    submitCreditLineIncreaseResponse,
    hasSubmittedCreditLineIncreaseThisSession,
    hasFormBeenResubmitted,
  ]);

  const isFormDisabled = !!scriptDetails?.disableInput;

  function renderTitle() {
    return (
      <h2 className={styles.header}>
        {toolTypeToName(ToolType.CreditLineIncrease)}
      </h2>
    );
  }

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetCreditLineIncreaseEligibilityResponseLoading ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetCreditLineIncreaseEligibilityResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getCreditLineIncreaseEligibilityResponseError}
                  refetch={getCreditLineIncreaseEligiblityResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={11}>
                <h2 className={styles.header}>
                  {toolTypeToName(ToolType.CreditLineIncrease)}
                </h2>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                {renderScriptMessage(
                  getCreditLineIncreaseEligibilityResponse.rejectionReason,
                  scriptDetails?.message,
                )}
                <div className={styles.creditLineIncrease}>
                  {strings.creditLineIncrease(
                    centsToDollarString(
                      getCreditLineIncreaseEligibilityResponse.maxIncreaseCents,
                    ),
                  )}
                  <div className={styles.maxNewCreditLine}>
                    {strings.maxNewCreditLine(
                      centsToDollarString(
                        getCreditLineIncreaseEligibilityResponse.maxCreditLineCents,
                      ),
                    )}
                  </div>
                </div>
                <form className={styles.container} onSubmit={onSubmit}>
                  <CustomAmountInput
                    label={strings.newCreditLimit}
                    amountCents={formState.newLimitCents.value}
                    onChange={(v) =>
                      setFormState((prev) => ({
                        ...prev,
                        newLimitCents: {
                          value: v,
                          isValid: !getNewCreditLimitValidationMessages(
                            centsToDollarString(
                              getCreditLineIncreaseEligibilityResponse.userData
                                .creditLimitCents,
                            ),
                            centsToDollarString(
                              getCreditLineIncreaseEligibilityResponse.maxCreditLineCents,
                            ),
                            v,
                          ),
                          validationMessages: undefined,
                        },
                      }))
                    }
                    onBlur={() =>
                      setFormState((prev) => ({
                        ...prev,
                        newLimitCents: {
                          ...prev["newLimitCents"],
                          validationMessages:
                            getNewCreditLimitValidationMessages(
                              centsToDollarString(
                                getCreditLineIncreaseEligibilityResponse
                                  .userData.creditLimitCents,
                              ),
                              centsToDollarString(
                                getCreditLineIncreaseEligibilityResponse.maxCreditLineCents,
                              ),
                              prev["newLimitCents"].value,
                            ),
                        },
                      }))
                    }
                    validationMessages={
                      formState["newLimitCents"].validationMessages
                    }
                    disabled={isFormDisabled}
                    showPopover={false}
                  />
                  <Select
                    options={getCreditLineIncreaseEligibilityResponse.reasons.map(
                      (r) => ({
                        key: r.id,
                        label: r.value,
                        value: r.id,
                      }),
                    )}
                    disabled={isFormDisabled}
                    onChange={(v) =>
                      setFormState((prev) => ({
                        ...prev,
                        reasonId: {
                          value: v,
                          isValid: !getReasonIdValidationMessages(v),
                          validationMessages: undefined,
                        },
                      }))
                    }
                    onBlur={() =>
                      setFormState((prev) => ({
                        ...prev,
                        reasonId: {
                          ...prev["reasonId"],
                          validationMessages: getReasonIdValidationMessages(
                            prev["reasonId"].value,
                          ),
                        },
                      }))
                    }
                    selectedValue={formState.reasonId.value}
                    label={strings.reason}
                    placeholder={strings.select}
                    validationMessages={
                      formState["reasonId"].validationMessages
                    }
                  />
                  <PhoneNumberInput
                    label={strings.contactPhoneNumber}
                    value={formState.phoneNumber.value}
                    onChange={(v) =>
                      setFormState((prev) => ({
                        ...prev,
                        phoneNumber: {
                          value: v,
                          isValid: !getPhoneNumberValidationMessages(v),
                          validationMessages: undefined,
                        },
                      }))
                    }
                    onBlur={() =>
                      setFormState((prev) => ({
                        ...prev,
                        phoneNumber: {
                          ...prev["phoneNumber"],
                          validationMessages: getPhoneNumberValidationMessages(
                            prev["phoneNumber"].value,
                          ),
                        },
                      }))
                    }
                    validationMessages={
                      formState["phoneNumber"].validationMessages
                    }
                    disabled={isFormDisabled}
                    showPopover={false}
                  />
                  <StyledButton
                    className={styles.submitButton}
                    type="submit"
                    disabled={isSubmitDisabled || isFormDisabled}
                  >
                    {strings.submitCLIRequest}
                  </StyledButton>
                </form>
              </Col>
              {scriptDetails ? (
                <Col offset={{ md: 3 }} md={6}>
                  <ConversationScript
                    scripts={scriptDetails.script}
                    scriptName={scriptDetails.name}
                    className={styles.conversationScript}
                  />
                </Col>
              ) : null}
            </Row>
          </>
        )}
      </Container>
      <NetworkErrorModal
        message={displayError}
        onRequestClose={() => setDisplayError("")}
      />
    </div>
  );
}
