import classNames from "classnames";
import { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { getDisplayError } from "../../lib/api/endpoints";
import { EnrollmentType, GetAccountResponse } from "../../lib/api/models";
import { useGetAutopayEnrollment } from "../../lib/api/queries";
import { ToolState } from "../AccountDetails";
import Container from "../common/Container";
import Spinner from "../common/Spinner";
import ToolLoadError from "../common/ToolLoadError";
import StyledButton from "../common/buttons/StyledButton";
import AddAutopay from "./AddAutopay";
import styles from "./ManageAutopay.module.css";
import UpdateAutopay from "./UpdateAutopay";
import CreateAutopayIneligible from "./UpdateAutopay/CreateAutopayIneligible";

type Props = {
  account: GetAccountResponse;
  className?: string;
  onClose: () => void;
  onComplete: (summaryWithoutTime: string) => void;
  onDisplayGeneralErrors: (error: unknown) => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  tool: ToolState;
  onRequestClose: () => void;
};

const strings = {
  error: "Error",
  loading: "Loading Tool",
  manageAutopay: "Manage Autopay",
  addAutopay: "Add Autopay",
  close: "Close",
};

export function ManageAutopay({
  account,
  className,
  onClose,
  onComplete,
  onDisplayGeneralErrors,
  onSetToolInProgress,
  tool,
  onRequestClose,
}: Props) {
  const getAutopayEnrollmentQuery = useGetAutopayEnrollment({
    partyId: account.partyId,
    dwbuid: account.currentDwbuid,
  });
  const { id: toolId } = tool;

  const getHeader = () => {
    if (getAutopayEnrollmentQuery.isLoading) {
      return strings.loading;
    } else if (getAutopayEnrollmentQuery.isError) {
      return strings.error;
    } else if (
      getAutopayEnrollmentQuery.data.enrollmentType ===
        EnrollmentType.Eligible &&
      !getAutopayEnrollmentQuery.data.isEnrolledAutopay
    ) {
      return strings.addAutopay;
    }
    return strings.manageAutopay;
  };

  useEffect(() => {
    if (
      getAutopayEnrollmentQuery.data &&
      getAutopayEnrollmentQuery.data.enrollmentType === EnrollmentType.Eligible
    ) {
      onSetToolInProgress(toolId, true);
    }
  }, [getAutopayEnrollmentQuery.data, onSetToolInProgress, toolId]);

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col md={11}>
            <h2 className={styles.header}>{getHeader()}</h2>
          </Col>
          <Col md={1}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              renderLoadingIndicator={false}
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        {getAutopayEnrollmentQuery.isLoading ? (
          <Row>
            <Col offset={{ xs: 6 }} xs={1}>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : getAutopayEnrollmentQuery.isError ? (
          <Row>
            <Col>
              <ToolLoadError
                toolName={getHeader()}
                errorDescription={getDisplayError(
                  getAutopayEnrollmentQuery.error,
                )}
                onRetry={getAutopayEnrollmentQuery.refetch}
              />
            </Col>
          </Row>
        ) : getAutopayEnrollmentQuery.data.enrollmentType ===
          EnrollmentType.Eligible ? (
          getAutopayEnrollmentQuery.data.isEnrolledAutopay ? (
            <UpdateAutopay
              account={account}
              eligibleAutopayEnrollmentResponse={getAutopayEnrollmentQuery.data}
              onComplete={onComplete}
              onDisplayGeneralErrors={onDisplayGeneralErrors}
            />
          ) : (
            <AddAutopay
              account={account}
              onComplete={onComplete}
              onDisplayGeneralErrors={onDisplayGeneralErrors}
            />
          )
        ) : (
          <CreateAutopayIneligible
            onClose={onClose}
            ineligibleAutopayEnrollmentResponse={getAutopayEnrollmentQuery.data}
          />
        )}
      </Container>
    </div>
  );
}
