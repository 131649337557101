import { useState } from "react";
import { Col, Row } from "react-grid-system";
import {
  EnrollmentType,
  GetAccountResponse,
  GetAutopayEnrollmentResponse,
  PartyType,
} from "../../../lib/api/models";
import {
  useRemoveAutopayEnrollment,
  useSkipAutopay,
} from "../../../lib/api/queries";
import { convertDateStringToMonthDayYearFomat } from "../../../lib/formatting";
import Subtool from "../../common/Subtool";
import StyledButton from "../../common/buttons/StyledButton";
import TextToggle from "../../common/buttons/TextToggle";
import { TextToggleOption } from "../../common/buttons/TextToggle/TextToggle";
import ActiveEnrollmentActions from "./ActiveEnrollmentActions";
import ActiveEnrollmentDetails from "./ActiveEnrollmentDetails";
import styles from "./UpdateAutopay.module.css";

const strings = {
  cancel: "Cancel",
  cardMember: "Card Member",
  reenrollmentInfo: (reenrollmentDate: string) =>
    `If you continue, you will be able to re-enroll the Card Member after ${reenrollmentDate}`,
  partyPrompt:
    "Please select whether this request was made by the Card Member or Third Party:",
  removeAutopay: "Remove Autopay Enrollment",
  skipAutopay: "Skip Autopay Payment",
  skipInfo: "Are you sure that you want to skip this Autopay Payment?",
  subheader: (accNum: string) => `Account ${accNum}`,
  summaryRemove: (dwbuid: string) => `Successfully removed autopay ${dwbuid}`,
  summarySkip: (dwbuid: string) => `Successfully skipped autopay ${dwbuid}`,
  thirdParty: "Third Party",
};

export type UpdateAutopayActions = "skip" | "remove";

const getActionText = (selectedAction: UpdateAutopayActions) => {
  let x: never;
  switch (selectedAction) {
    case "remove":
      return strings.removeAutopay;
    case "skip":
      return strings.skipAutopay;
    default:
      x = selectedAction; // exhaustiveness check
      return x;
  }
};

const getActionSubheader = (
  selectedAction: UpdateAutopayActions,
  reenrollmentDate: string,
) => {
  let x: never;
  switch (selectedAction) {
    case "remove":
      return strings.reenrollmentInfo(reenrollmentDate);
    case "skip":
      return strings.skipInfo;
    default:
      x = selectedAction; // exhaustiveness check
      return x;
  }
};

const PARTY_SELECTION: [
  TextToggleOption<PartyType>,
  TextToggleOption<PartyType>,
] = [
  {
    key: "CM",
    label: strings.cardMember,
  },
  {
    key: "3RD-PARTY",
    label: strings.thirdParty,
  },
];

type Props = {
  account: GetAccountResponse;
  eligibleAutopayEnrollmentResponse: GetAutopayEnrollmentResponse & {
    enrollmentType: EnrollmentType.Eligible;
  };
  onComplete: (summaryWithoutTime: string) => void;
  onDisplayGeneralErrors: (error: unknown) => void;
};

export function UpdateAutopay({
  account,
  eligibleAutopayEnrollmentResponse,
  onComplete,
  onDisplayGeneralErrors,
}: Props) {
  const { partyId, currentDwbuid: dwbuid } = account;
  const [selectedAction, setSelectedAction] = useState<UpdateAutopayActions>();
  const [party, setParty] = useState<PartyType>();

  const {
    mutateAsync: removeAutopayEnrollment,
    isLoading: isRemoveAutopayEnrollmentLoading,
  } = useRemoveAutopayEnrollment();

  const { mutateAsync: skipAutopay, isLoading: isSkipAutopayLoading } =
    useSkipAutopay();

  const onRemoveAutopayEnrollment = () => {
    if (!party) {
      return;
    }

    removeAutopayEnrollment({
      partyId,
      dwbuid,
      party,
    })
      .then(() => onComplete(strings.summaryRemove(dwbuid)))
      .catch(onDisplayGeneralErrors);
  };

  const onSkipAutopay = () => {
    if (!party) {
      return;
    }

    skipAutopay({
      partyId,
      dwbuid,
      party,
    })
      .then(() => onComplete(strings.summarySkip(dwbuid)))
      .catch(onDisplayGeneralErrors);
  };

  return (
    <Row>
      <Col xs={4}>
        <ActiveEnrollmentDetails
          account={account}
          enrollmentInfo={eligibleAutopayEnrollmentResponse.enrollmentInfo}
          enrolledDate={eligibleAutopayEnrollmentResponse.enrolledDate}
        />
      </Col>
      <Col xs={4}>
        <ActiveEnrollmentActions
          onSkip={
            eligibleAutopayEnrollmentResponse.isAutopaySkipped
              ? undefined
              : () => setSelectedAction("skip")
          }
          onRemove={() => setSelectedAction("remove")}
        />
      </Col>
      {selectedAction ? (
        <Col xs={4}>
          <Subtool className={styles.actionableContainer}>
            <span className={styles.subheader}>
              {getActionText(selectedAction)}
            </span>
            <div className={styles.partyPrompt}>{strings.partyPrompt}</div>
            <TextToggle
              className={styles.partyTextToggle}
              options={PARTY_SELECTION}
              onSelectOption={setParty}
              selectedOption={party}
            />
            <div className={styles.info}>
              {getActionSubheader(
                selectedAction,
                convertDateStringToMonthDayYearFomat(
                  eligibleAutopayEnrollmentResponse.reEnrollDate,
                ),
              )}
            </div>
            <StyledButton
              className={styles.selectedActionButton}
              onClick={
                selectedAction === "remove"
                  ? onRemoveAutopayEnrollment
                  : onSkipAutopay
              }
              disabled={!party}
              isLoading={
                isRemoveAutopayEnrollmentLoading || isSkipAutopayLoading
              }
            >
              {getActionText(selectedAction)}
            </StyledButton>
            <StyledButton
              className={styles.cancelButton}
              variant="secondary"
              onClick={() => {
                setSelectedAction(undefined);
                setParty(undefined);
              }}
            >
              {strings.cancel}
            </StyledButton>
          </Subtool>
        </Col>
      ) : null}
    </Row>
  );
}
