import { useState } from "react";
import { Col, Row } from "react-grid-system";
import greenCheck from "../../../assets/green-filled-check.svg";
import {
  EnrollAutopayRequest,
  GetAccountResponse,
  PartyType,
  ValidationErrors,
} from "../../../lib/api/models";
import { useEnrollAutopay } from "../../../lib/api/queries";

import classNames from "classnames";
import {
  getNonGenericValidationList,
  mergeValidation,
  setValidationErrorsAndDisplayGeneralErrors,
} from "../../../lib/validation";
import Subtool from "../../common/Subtool";
import StyledButton from "../../common/buttons/StyledButton";
import TextToggle from "../../common/buttons/TextToggle";
import { TextToggleOption } from "../../common/buttons/TextToggle/TextToggle";
import StyledInput from "../../common/inputs/StyledInput";
import { useAutopayEnrollmentForm } from "./AddAutopay.hooks";
import styles from "./AddAutopay.module.css";
import PaymentAmountSelector from "./PaymentAmountSelector";

const strings = {
  abaNumber: "ABA Number",
  accountInfoSubheader: "Account Information",
  amountInfoSubheader: "Amount",
  cardMember: "Card Member",
  complete: "Complete",
  ddaNumber: "DDA Number",
  enroll: "Enroll in Autopay",
  firstName: "First Name",
  holderSubheader: "Holder",
  lastName: "Last Name",
  one: "1",
  summary: (dwbuid: string) => `Successfully added autopay ${dwbuid}`,
  thirdParty: "Third Party",
  three: "3",
  two: "2",
};

const TO_CENTS = 100;

type NetworkFieldsToValidate =
  | "firstName"
  | "lastName"
  | "abaNumber"
  | "ddaNumber"
  | "amountCents";

const PARTY_SELECTION: [
  TextToggleOption<PartyType>,
  TextToggleOption<PartyType>,
] = [
  {
    key: "CM",
    label: strings.cardMember,
  },
  {
    key: "3RD-PARTY",
    label: strings.thirdParty,
  },
];

type Props = {
  account: GetAccountResponse;
  onComplete: (summaryWithoutTime: string) => void;
  onDisplayGeneralErrors: (error: unknown) => void;
};

export function AddAutopay({
  account,
  onComplete,
  onDisplayGeneralErrors,
}: Props) {
  const { partyId, currentDwbuid } = account;
  const { mutateAsync: enrollAutopay, isLoading: isAddAutopayLoading } =
    useEnrollAutopay();
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  const [
    {
      party,
      firstName,
      lastName,
      abaNumber,
      ddaNumber,
      selectedPaymentAmount,
      fixedAmountInput,
      isComplete: ableToSubmit,
      validationMessages,
      isPersonalInfoInvalid,
      isAccountInfoInvalid,
      isPaymentAmountInvalid,
    },
    { generalDispatch, getValidationMessages, clearValidationMessages },
  ] = useAutopayEnrollmentForm();

  const finalValidation = mergeValidation(
    networkValidationMessages ?? {},
    validationMessages,
  );

  const isComplete =
    getNonGenericValidationList(finalValidation).length < 1 && ableToSubmit;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isComplete || !selectedPaymentAmount) {
      return;
    }

    const fixedAmountCents =
      Number(fixedAmountInput.split(".")[0].replaceAll(",", "")) * TO_CENTS;

    const requestBody: EnrollAutopayRequest = {
      dwbuid: currentDwbuid,
      abaNumber,
      ddaNumber,
      amount:
        selectedPaymentAmount === "FIXED"
          ? {
              type: selectedPaymentAmount,
              amountCents: fixedAmountCents,
            }
          : { type: selectedPaymentAmount },
      partyId,
    };

    if (party === "3RD-PARTY") {
      requestBody.thirdParty = { firstName, lastName };
    }
    enrollAutopay(requestBody)
      .then(() => onComplete(strings.summary(currentDwbuid)))
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  const clearNetworkValidationMessage = (key: NetworkFieldsToValidate) => {
    if (networkValidationMessages) {
      setNetworkValidationMessages((prev) => ({
        ...prev,
        [key]: [],
      }));
    }
  };

  return (
    <Row>
      <Col xs={4}>
        <Subtool className={styles.subtool}>
          <div className={styles.subheaderContainer}>
            {isPersonalInfoInvalid ? (
              <div className={styles.subtoolNumberOne}>{strings.one}</div>
            ) : (
              <>
                <div
                  className={classNames(
                    styles.subtoolNumberOne,
                    styles.validNumber,
                  )}
                >
                  {strings.one}
                </div>
                <img
                  className={styles.greenCheck}
                  src={greenCheck}
                  alt={strings.complete}
                />
              </>
            )}
            <span className={styles.subheader}>{strings.holderSubheader}</span>
          </div>
          <TextToggle
            className={styles.partySelectionTextToggle}
            options={PARTY_SELECTION}
            onSelectOption={(v) => {
              generalDispatch({ party: v });
              clearValidationMessages("firstName");
              clearValidationMessages("lastName");
            }}
            selectedOption={party}
          />
          {party === "3RD-PARTY" ? (
            <StyledInput
              className={styles.firstNameInput}
              value={firstName}
              onChange={(e) => {
                generalDispatch({ firstName: e.currentTarget.value });
                clearValidationMessages("firstName");
                clearNetworkValidationMessage("firstName");
              }}
              onBlur={() => getValidationMessages("firstName")}
              label={strings.firstName}
              validationMessages={finalValidation["firstName"]}
            />
          ) : null}
          {party === "3RD-PARTY" ? (
            <StyledInput
              className={styles.lastNameInput}
              value={lastName}
              onChange={(e) => {
                generalDispatch({ lastName: e.currentTarget.value });
                clearValidationMessages("lastName");
                clearNetworkValidationMessage("lastName");
              }}
              onBlur={() => getValidationMessages("lastName")}
              label={strings.lastName}
              validationMessages={finalValidation["lastName"]}
            />
          ) : null}
        </Subtool>
      </Col>
      <Col xs={4}>
        <Subtool className={styles.subtool}>
          <div className={styles.subheaderContainer}>
            {isAccountInfoInvalid ? (
              <div className={styles.subtoolNumberTwo}>{strings.two}</div>
            ) : (
              <>
                <div
                  className={classNames(
                    styles.subtoolNumberTwo,
                    styles.validNumber,
                  )}
                >
                  {strings.two}
                </div>
                <img
                  className={styles.greenCheck}
                  src={greenCheck}
                  alt={strings.complete}
                />
              </>
            )}
            <span className={styles.subheader}>
              {strings.accountInfoSubheader}
            </span>
          </div>
          <StyledInput
            className={styles.abaNumberInput}
            value={abaNumber}
            onChange={(e) => {
              generalDispatch({ abaNumber: e.currentTarget.value });
              clearValidationMessages("abaNumber");
              clearNetworkValidationMessage("abaNumber");
            }}
            label={strings.abaNumber}
            onBlur={() => getValidationMessages("abaNumber")}
            validationMessages={finalValidation["abaNumber"]}
          />
          <StyledInput
            className={styles.ddaNumberInput}
            value={ddaNumber}
            onChange={(e) => {
              generalDispatch({ ddaNumber: e.currentTarget.value });
              clearValidationMessages("ddaNumber");
              clearNetworkValidationMessage("ddaNumber");
            }}
            onBlur={() => getValidationMessages("ddaNumber")}
            label={strings.ddaNumber}
            validationMessages={finalValidation["ddaNumber"]}
          />
        </Subtool>
      </Col>
      <Col xs={4}>
        <Subtool className={styles.subtool}>
          <div className={styles.subheaderContainer}>
            {isPaymentAmountInvalid ? (
              <div className={styles.subtoolNumberThree}>{strings.three}</div>
            ) : (
              <>
                <div
                  className={classNames(
                    styles.subtoolNumberThree,
                    styles.validNumber,
                  )}
                >
                  {strings.three}
                </div>
                <img
                  className={styles.greenCheck}
                  src={greenCheck}
                  alt={strings.complete}
                />
              </>
            )}
            <span className={styles.subheader}>
              {strings.amountInfoSubheader}
            </span>
          </div>
          <form onSubmit={onSubmit}>
            <PaymentAmountSelector
              selectedPaymentAmount={selectedPaymentAmount}
              onSelectPaymentAmount={(v) => {
                generalDispatch({ selectedPaymentAmount: v });
                clearValidationMessages("amountCents");
                clearNetworkValidationMessage("amountCents");
              }}
              customInput={fixedAmountInput}
              onChangeCustomInput={(v) => {
                generalDispatch({ fixedAmountInput: v });
                clearValidationMessages("amountCents");
                clearNetworkValidationMessage("amountCents");
              }}
              className={styles.selector}
              customInputValidation={finalValidation["amountCents"]}
              onBlurCustomInput={() => getValidationMessages("amountCents")}
            />
            <StyledButton
              className={styles.enrollmentButton}
              variant="primary"
              disabled={!isComplete}
              isLoading={isAddAutopayLoading}
              type="submit"
            >
              {strings.enroll}
            </StyledButton>
          </form>
        </Subtool>
      </Col>
    </Row>
  );
}
